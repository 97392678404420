import { useRef } from "react";
import { ScrollArea } from "../ui/scroll-area"
import MessageBubble, { ChatBubbleProp } from "./MessageBubble"


/**
 * Create a chat app which
 * @one Displays messages left and right side of the box depending of if its either sender or receiver (jean)
 * @two takes an array of conversation and displays it porperly
 * h-[590px] w-[1250px]
 */
const ChatWindow=({conversation, onReady}:{conversation:ChatBubbleProp[], onReady:() => void})=>{
    const showChat=conversation && conversation.length
    // Create a ref for the ScrollArea's viewport
  const viewportRef = useRef<HTMLDivElement>(null);

  const initialDrop = () => {
    if (viewportRef.current) {
        viewportRef.current.scrollTop = viewportRef.current.scrollHeight;
    }
  } 
  // Effect to scroll to the bottom when the conversation updates
  // useEffect(() => {
  //   //console.log('can drop window?: ',!dropWindowDown)
  //   if (viewportRef.current && !dropWindowDown) {
  //     // Scroll to the bottom of the chat when the conversation changes
  //     viewportRef.current.scrollTop = viewportRef.current.scrollHeight;
  //   }
  // }, [conversation, dropWindowDown]); // Trigger this effect whenever the conversation updates

  return (
    <ScrollArea
      viewportRef={viewportRef} // Pass the viewportRef to the ScrollArea component
      className="h-full pr-4 overflow-y-auto">
      {showChat
        ? conversation.map(({ from, text }, index) => (
            <MessageBubble key={index} from={from} text={text} onReady={onReady} scrollBottom={initialDrop} />
          ))
        : <div></div>}
    </ScrollArea>
  );
};


export default ChatWindow;