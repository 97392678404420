import { Track } from 'openapi/api/model/track';
import { createContext, useState } from 'react';


export const TrackContext = createContext({
    track: undefined as Track|undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setTrack: (_track:Track|undefined)=>{},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TrackContextProvider = ({children}: any) => {
    const [userTrack, setUserTrack] = useState<Track>();
    const setTrack = (track:Track|undefined) => {
        setUserTrack(track);
        
    }


    const value = {
        track: userTrack,
        setTrack: setTrack,
    }

    return(
        <TrackContext.Provider value={value}>
            {children}
        </TrackContext.Provider>
    )
}

export default TrackContextProvider;