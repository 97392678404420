import { Title } from '@/components/ui/Title';
import { Card, CardTitle, CardContent } from '@/components/ui/card';
import { 
    useGetTracks, 
    useGetTracksSearch } from '../../../openapi/api/endpoints/default/default';
import { Button } from '@/components/ui/button';
import { useNavigate } from '@tanstack/react-router';
import { useContext } from 'react';
import { TrackContext } from '@/store/track-context';
import { GetEvaluationprogress200OngoingEvalProgressionItem, TrackHomePage } from 'openapi/api/model'; 
//import { checkSEB } from '../Project/CreateProject/utils/checkSEB';
import { AxiosError } from 'axios';


const HiringCampaignList=()=>{
    const navigate=useNavigate({from:'/home'})
    const {data:allUserTracks}=useGetTracks()
    const {data:ongoingTracks}=useGetTracksSearch({trackFormat:'onGoingTracks'},{query:{refetchInterval:5000}})
    const {setTrack}=useContext(TrackContext)
    const storageEval = sessionStorage.getItem("ongoingEvalProgression");
        let ongoingEvalProgression: GetEvaluationprogress200OngoingEvalProgressionItem | null;
    
        if(storageEval){
            ongoingEvalProgression = JSON.parse(storageEval) as GetEvaluationprogress200OngoingEvalProgressionItem;
        }else{
            ongoingEvalProgression = null;
        }
    
    const onClick=async(ongoingTrack:TrackHomePage)=>{
        try{
            //await checkSEB()
            setTrack(allUserTracks?.find((userTrack)=>userTrack._id===ongoingTrack._id))
            navigate({to:`/course/${ongoingTrack.unmatchedProjectID}/${ongoingTrack.unmatchedProjectSkill}/${ongoingTrack.evalType}`})
        }catch(err){
            if((err as AxiosError).response?.data==='Please use the provided SEB'){
                navigate({to:`/course/${ongoingTrack.unmatchedProjectID}/downloadSEB`})
            }else{
                throw err
            }
        }
        
    } 
    
    return (
    <>
        <Card className="flex flex-col basis-1/2 m-4 p-4 bg-white border-none">
            <CardTitle className="flex justify-center items-center text-white">
                <Title>Evaluations Pending Completion</Title>
            </CardTitle>
            <CardContent className="h-[290px] flex-auto flex-col px-2 py-4 bg-white overflow-auto">
                {  
                    ongoingTracks?.map((ongoingTrack)=>
                        <Card className="flex-1 bg-gray-200 border-none mb-4">
                            <CardContent className="p-2">
                                <div className="flex h-auto p-2">
                                    <div className='flex flex-row w-full'>
                                        <div className='flex flex-col w-5/6 pl-[20px]'>
                                            <div className="flex w-full items-center font-bold text-black max-xl:truncate">
                                                {ongoingTrack.trackName} @{ongoingTrack.companyName} 
                                            </div>
                                            <div className="flex w-full items-center font-normal text-black max-xl:truncate">
                                                Next evaluation: {ongoingTrack.unmatchedProjectSkill} ({ongoingTrack.evalType})
                                            </div>
                                        </div>
                                        
                                        <div className='flex w-1/6'>
                                        <Button 
                                            className='w-full h-full bg-teal100 hover:bg-blue100' 
                                            onClick={()=>onClick(ongoingTrack)}
                                            disabled={(
                                                (ongoingEvalProgression?.projectID==ongoingTrack.unmatchedProjectID)
                                                &&(ongoingEvalProgression?.evalType==ongoingTrack.evalType)
                                            )}>{(
                                                (ongoingEvalProgression?.projectID==ongoingTrack.unmatchedProjectID)
                                                &&(ongoingEvalProgression?.evalType==ongoingTrack.evalType)
                                            ) ? 'Pending':'Start'}</Button>
                                        </div> 
                                    </div> 
                                </div>
                            </CardContent>
                        </Card>
                    ).reverse()  //reverse the order of the list
                } 
                {!ongoingTracks?.length && <div className='flex justify-center items-center text-black h-full'>No ongoing evaluations</div>}
            </CardContent>
        </Card>
    </>
  );
}

export default HiringCampaignList;