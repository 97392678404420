import { Send } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import Timer from '@/components/ui/custom/Timer'
import { useForm } from 'react-hook-form'
import useChat from '@/utils/Websocket/useChat'
import ChatWindow from '@/components/Chat/ChatWindow'
import { useRef, useState } from 'react'

const theme = {
  mint100: '#ccfbf1',
  teal100: '#26A69A',
  blue100: '#101820',
}


const ChatEvaluation = ({sub,projectId,hiringCampaignId,trackId,skill,blockerFn}:{sub:string,projectId:string,hiringCampaignId:string,trackId:string,skill:string,blockerFn:() => void}) => {
  const {register, handleSubmit, setValue}=useForm()
  const [botWriting, setBotWriting] = useState<boolean>(false)
  //const [currentIndex, setCurrentIndex] = useState(0)
  const containerRef = useRef<HTMLDivElement | null>(null);

  const onSendMessage = () => {
    setBotWriting(true);
    setValue("ClientMessage", "");

    // Reset textarea height through the wrapper `div`
    if (containerRef.current) {
      const textarea = containerRef.current.querySelector("textarea");
      if (textarea) {
        textarea.style.height = "auto";
        textarea.rows = 1;
      }
    }
  };

  const onReceivingMessage=()=>{
    setBotWriting(false); 
  }

  const {currentMessages,sendMessage}=useChat({onSendMessage,sub,projectId,hiringCampaignId,trackId,skill})

  const goToOverview= () => {
    blockerFn();
  }


  return (
    <div className="flex flex-col py-2 px-32 h-[100dvh] w-full">
      <Card className="flex-auto w-full h-full flex flex-col" style={{ backgroundColor: theme.blue100, color: theme.mint100 }}>
          <CardHeader className="flex-shrink-0 items-center">
              <CardTitle className="text-center text-white">Evaluation Chat</CardTitle>
              <Timer timerMinutes={45} timerDone={goToOverview}/>
            </CardHeader>
            <CardContent className="flex-grow overflow-hidden">
                <ChatWindow conversation={currentMessages} onReady={onReceivingMessage}/>
            </CardContent>
            <CardFooter className="flex-shrink-0">
                <form className="flex grow w-full" onSubmit={handleSubmit(sendMessage)}>
                <div ref={containerRef} className="flex flex-auto mr-2">
              <textarea
                id="message"
                placeholder="Send a message..."
                className="w-full text-white bg-gray-600 border-none"
                style={{
                  resize: "none",
                  overflowY: "auto",
                  height: "auto",
                  maxHeight: `${24 * 3}px`, // Limit to 3 rows
                  borderRadius: "5px",
                  paddingLeft: "5px",
                  paddingTop: "3px",
                }}
                rows={1} // Starts with a single row
                onInput={(e) => {
                  const maxRows = 3;
                  const lineHeight = 24;
                  const target = e.target as HTMLTextAreaElement;
                  // Adjust height based on content
                  target.style.height = "auto"; // Reset to recalculate height
                  target.style.height = `${Math.min(target.scrollHeight, maxRows * lineHeight)}px`; // Grow up to max rows
                }}
                {...register("ClientMessage")}
              />
            </div>

                    {botWriting && <Button disabled
                      type='submit'
                      style={{ backgroundColor: theme.teal100, color: theme.blue100 }}
                    >
                      <Send className="h-4 w-4" />
                      <span className="sr-only">Send</span>
                    </Button>}
                    {!botWriting && <Button 
                      type='submit'
                      style={{ backgroundColor: theme.teal100, color: theme.blue100 }}
                    >
                      <Send className="h-4 w-4" />
                      <span className="sr-only">Send</span>
                    </Button>}
                </form>
            </CardFooter>
        </Card>
    </div>
  )
}

export default ChatEvaluation;