import { useEffect, useState } from "react";
import {ChatSocket} from '@/utils/Websocket/socket'
import { ChatBubbleProp } from "@/components/Chat/MessageBubble";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useChat= ({sub,projectId,hiringCampaignId,trackId,skill,onSendMessage}:{sub:string,projectId:string,hiringCampaignId:string,trackId:string,skill:string,onSendMessage:()=>void})=>{
  const [chatMessage, setChatMessage] = useState<ChatBubbleProp[]>([]);
  useEffect(() => {
    // no-op if the socket is already connected
    console.log('Attempting to connect...')
    // Define the Zod schema based on the ChatContext type
    ChatSocket.io.opts.query =  {
      sub,
      skill,
      trackId,
      hiringCampaignId,
      projectId
    };
    ChatSocket.connect();
    return () => {
      ChatSocket.disconnect();
      };
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sendMessage=({ClientMessage}:any)=>{//sedning messages
    console.log("emitting ClientMsg: ",ClientMessage)
    ChatSocket.emit("answer",ClientMessage)
    onSendMessage()
    setChatMessage((chatMessage)=>[
      ...chatMessage,
      {
        from:'Me',
        text:ClientMessage
      }
    ])
  }
  useEffect(() => {//receiving messages
    const onMessage=(value:string)=>{
      console.log(value)
      setChatMessage((chatMessage)=>[
        ...chatMessage,
        {
          from:'Bot',
          text:value
        }
      ])
      
    }
    ChatSocket.on('question', onMessage);
    return () => {
      ChatSocket.off('question', onMessage);
    };
  }, [chatMessage],);
  return {sendMessage,currentMessages:chatMessage}
}
export default useChat