import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { queryClient } from "@/App";
import { ObjectType } from "@/pages/Authentication/AuthUtils/authTypes";
export type AuthProctoring = {
    trackId:string,
    projectId:string,
    evalType:string
}

/* Get port for video proctoring */
export const useInitVideoProctoring=({enabled,refetchOnWindowFocus}:{enabled:boolean,refetchOnWindowFocus?:boolean})=>{ 
    return useQuery({
        queryKey: ['port'],
        queryFn: initVideoProctoring,
        enabled: enabled,
        refetchOnWindowFocus: refetchOnWindowFocus
    })
}

export const initVideoProctoring = async() => {
    let requests = [];
    const resp=queryClient.getQueryData(['Auth']) as ObjectType
    const session=resp?.session
    const token=session?.idToken
    

    try{
        let response;
        
        if(navigator.platform.includes('Win')){
            for(let i = 55000; i <= 55100; i++){
                const port = axios.get(`http://localhost:${i}/testConnection`)
                requests.push(port)
            }
            response = await Promise.any(requests);
        }else{
            response = await axios.get(`http://34.202.92.155:8080/testConnection`);
        }

        if (response && response.data?.port_number) {
            const url = navigator.platform.includes('Win') 
                ? `http://localhost:${response.data.port_number}/authenticateUser`
                : `http://34.202.92.155:${response.data.port_number}/authenticateUser`;

            await axios.post(url, {},
                {
                    headers: {
                        Authorization: `Bearer ${token?.jwtToken}`,
                    },
                }
            );

            localStorage.setItem('Port', `${response.data.port_number}`);
            return { port: response.data.port_number };
        } else {
            throw new Error('Unable to find an available port');
        }

    }catch(error){
        console.log(new Error(`${(error as AxiosError).message}`));
    }
}


/* Start video proctoring */
export const useStartVideoProctoring=()=>{ 
    return useMutation({
        mutationFn: startVideoProctoring,
    })
}

export const startVideoProctoring = async({port,authData}:{port:string,authData:AuthProctoring}) => {
    let protocol
    if(navigator.platform.includes('Win')){
        protocol = 'http://localhost'
    }else{
        protocol = 'http://34.202.92.155'
    }

    try{
        const response = await axios.post(`${protocol}:${port}/startEvaluation`,{authData},{})
        return response.data; 
    }catch(err){
        console.log(new Error(`${(err as AxiosError).message}`));
    }
}


export const stopVideoProctoring = async() => {
    const cachedPort = localStorage.getItem('Port')??''
    let protocol
    if(navigator.platform.includes('Win')){
        protocol = 'http://localhost'
    }else{
        protocol = 'http://34.202.92.155'
    }
    
    try{
        const response = await axios.get(`${protocol}:${cachedPort}/stopEvaluation`)
        return response.data; 
    }catch(err){
        console.log(new Error(`${(err as AxiosError).message}`));
    }
}

/* export const useSetupVideoProctoring=()=>{ 
    return useQuery({
        queryKey: ['port'],
        queryFn: ()=>setupVideoProctoring(),
        //enabled: enabled,
    })
}


export const setupVideoProctoring = async() => {
    
    try{
        //const { data:isSeb }=useGetIsSEB()
        console.log('Setup VP')
        const { data:port }=useGetPort({enabled: true})
        console.log('Port found: ',port)
        const { mutate }=usePostAuthenticateUser({
            onSuccess:(data)=>{
                console.log('User authenticated: ',data)
            },
            onError: (error)=>{
            console.log(`Could not authenticate: ${(error as AxiosError).message}`)
            }
        })
        //mutate({port:port?.port.toString()??'55000'})
        useEffect(() => {
            mutate({port:port?.port.toString()??'3000'})
          }, [port]);
        console.log('Setup complete!')
        return 'Setup complete!'
    }catch(err){
        throw new Error(`${(err as AxiosError).message}`);
    }
} */