import { Button } from "@/components/ui/button"
import CircularProgress from '@mui/joy/CircularProgress';
import { AlertOctagon } from "lucide-react";
import { useContext, useEffect, useMemo, useState } from "react"
import { useNavigate } from "@tanstack/react-router";
import { BasicModal } from "./components/LoadingWorkspaceDialog";
import useEvaluation from "./customHooks/useEvaluation";
import { toast } from "sonner";
import { TrackContext } from "@/store/track-context";
import { frameworkType } from "@/utils/Types/skills";
import { AuthProctoring, useStartVideoProctoring } from "@/pages/Project/VideoProctoring/VideoProctoringHooks";



const WorkspaceComponent = ({projectId}:{projectId:string}) => {
    const navigate=useNavigate({from:'/'})
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const {track:currentTrack}=useContext(TrackContext)
    const currentProjectSkill=currentTrack?.trackProgression.find((trackProgressionItem)=>trackProgressionItem.projectID===projectId)?.skill
    const cachedPort = localStorage.getItem('Port')??''
    const {mutate:startVP} = useStartVideoProctoring()
    const authData: AuthProctoring = {
      trackId: currentTrack?._id??'',
      projectId: projectId,
      evalType: 'project'
    }
  const {startEvaluation,workspaceDetails,workspaceState,deleteWorkspace,errors,isSuccess}=useEvaluation({projID:projectId})
  const spinAdditionalTabs=(stackPosition: "frontend" | "backend")=>{
    window.open(workspaceDetails?.url)       
    if(stackPosition==='backend'){
      window.open('https://hoppscotch.io/')
    }
  }



  useEffect(()=>{
    if(workspaceState?.status === 'podReady' && isSuccess){
      setTimeout(()=>{
        handleClose()
        if(workspaceDetails?.url){
          const stackPosition= Object.entries(frameworkType).map(([key, frameworks]) => {
            return frameworks.includes(currentProjectSkill??'') ? key : undefined;
          }).find(cat => cat !== undefined) as "frontend" | "backend";
          spinAdditionalTabs(stackPosition)
        }
        startVP({port:cachedPort,authData:authData})
        sessionStorage.setItem("ongoingEvalProgression", JSON.stringify({projectName: currentProjectSkill, skill: currentProjectSkill, projectID: projectId, evalType: 'project'}));
        //sessionStorage.setItem("link",window.location.href)
        navigate({
          to:'/course/$courseId/$skill/project/$templateId',
          params:{
              courseId:projectId,
              skill:workspaceDetails?.workspaceArgs?.skill,
              templateId:workspaceDetails?.workspaceArgs?.templateId
            }
        })
      },1000)
    }
    if(errors){
      toast.error("Unable to start evaluation!",{
        description: `${errors?.message??''}`,
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#dc2626'
        }
      })
    }
  },[isSuccess])



    const StatusComponent = () => {
      const component = useMemo(() => {
        if (workspaceState?.status === 'workspaceLoading'|| isSuccess===false) {
          setOpen(true);
          return(
            <BasicModal open={open} handleClose={handleClose}>
              <CircularProgress variant="soft" sx={{"--CircularProgress-progressColor":'#26A69A'}}/>
              Building workspace...
            </BasicModal>
          );
        } else if (workspaceState?.status === 'podReady') {
          return (
            <Button onClick={deleteWorkspace} className="rounded-full bg-teal100 hover:bg-blue100">Delete workspace</Button>
          );
        } else if (workspaceState?.status === 'error'||errors) {
          return(
            <div className="flex flex-row justify-center items-center space-x-2 p-4">
              <AlertOctagon color="#bb2124"/>
              Failed to initialize workspace; try again later
              <Button onClick={deleteWorkspace} className="rounded-full bg-teal100 hover:bg-blue100">Delete workspace</Button>
            </div>
          );
        } else if(workspaceState?.status === 'podDeleted'){
          return(
            <Button onClick={startEvaluation} className="rounded-full bg-teal100 hover:bg-blue100">Open Workspace</Button>
          )
        }
        else {
          return(
            <Button onClick={startEvaluation} className="rounded-full bg-teal100 hover:bg-blue100">Open Workspace</Button>
          );
        }
      }, []); // Dependencies
    
      return component;
    };

  return (
    <div><StatusComponent/></div>
  )
}

export default WorkspaceComponent

