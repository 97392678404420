/* eslint-disable @typescript-eslint/no-explicit-any */
import { MainAppRoute } from "@/rootRoutes/MainApp";
import { Route, useParams } from "@tanstack/react-router";
import { useGetProjectsProjectId } from "../../../openapi/api/endpoints/default/default";
import Dashboard from "../Overview/Components/Dashboard";
import HeaderComponent from "@/components/Header/Header";
import { useContext } from "react";
import { TrackContext } from "@/store/track-context";
import { capitalizeFirstLetter } from "@/utils/Functions/Functions";
import { ovr } from "@/mockData/ChartData";

const ProjectResults = () => {
  const { courseId } = useParams({ from: "/" });
  //const navigate=useNavigate({from:'/auth'})
  const {track}=useContext(TrackContext)
  const projectFromTrack = track?.trackProgression.find((proj)=>proj.projectID==courseId)
  const { data, isLoading } = useGetProjectsProjectId(courseId);


  const macro = data?.results.macro;

  if (isLoading) {
    return (
      <div className="flex flex-col p-4 bg-gray-100 min-h-screen max-md:w-screen overflow-x-auto">
        ...Loading
      </div>
    );
  }

  return (
    <div className="flex flex-col p-4 bg-gray-100 min-h-screen max-md:w-screen overflow-x-auto">
      <HeaderComponent title={capitalizeFirstLetter(projectFromTrack?.skill ?? "") ?? ""} />
      <Dashboard
        chartData={macro??ovr}
        performance={[
          data?.results?.performance?.overallSolutionFitness?.value ?? 0,
          data?.results?.performance?.security?.value ?? 0,
          data?.results?.performance?.performance?.value ?? 0,
          data?.results?.performance?.responsiveness?.value ?? 0,
        ]}
      />
    </div>
  );
};

const ProjectResultsRoute = new Route({
  getParentRoute: () => MainAppRoute,
  path: "course/$courseId/results",
  component: ProjectResults,
  stringifyParams: ({ courseId }) => ({ courseId: `${courseId}` }),
});

export default ProjectResultsRoute;
