/* eslint-disable @typescript-eslint/no-explicit-any */
import Editor from '@monaco-editor/react';
import { useContext, useRef } from 'react';
import { Button } from '../ui/button';
import { Card } from '../ui/card';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import React from 'react';
import usePython from '@/pages/Project/CreateProject/components/Python/utils/usePython';
import { AlgoEvaluation, AlgoProjectContext } from 'openapi/api/model';
import { TestCases } from '@/pages/Project/CreateProject/components/Python/utils/contexts';
import useBlinker from '@/pages/Project/CreateProject/components/Python/utils/useBlinker';
import returnTestCoverage  from '@/pages/Project/CreateProject/components/Python/utils/processCompilation';
import { TrackContext } from '@/store/track-context';
import AssignmentSubmission from '@/pages/Project/LoadingComponent';




const CodeEditor = ({skill,context,setCoverage}:{skill?:string,context:AlgoProjectContext,setCoverage:(coverage:AlgoEvaluation)=>void}) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const editorRef = useRef<any>(null);
    const {output, runPythonCode,pyodide} = usePython({tests:context.test_cases as TestCases[]})
    const {blinkCursor}=useBlinker()
    const {track}=useContext(TrackContext)



    const handleEditorDidMount=(editor:any)=>{
        editorRef.current = editor;
    }

    const compileCode = async () => {
      const code=editorRef.current.getValue() as string
      const result = await runPythonCode({ code }); // Await result directly
      const testCoverage=result ? returnTestCoverage(result)??0 : 0
      if (code) {
        const evalResult={
          code:editorRef.current.getValue(),
          hiringCampaignId:track?.hiringCampaignId??'',
          skill:skill as 'python',
          templateId:track?.trackProgression.find((project)=>project.skill===skill)?.templateId??'',
          trackID:track?._id??'',
          unitTestPassRate:testCoverage,
          sub:track?.sub,
          _id:track?.trackProgression.find((project)=>project.skill===skill)?.projectID??''
        }
        setCoverage(evalResult); // Set the coverage once processed
        console.log({evalResult})
      }
    } 
  
  
    return (
        <div className='flex flex-col rounded-xl h-full'>
          <AssignmentSubmission 
            loadingTitle="Setting Up Your Python Environment"
            isSubmitting={!pyodide} 
            loadingMessage="Please hold on for a moment while we load the Python compiler. Your coding environment will be ready shortly!"/>
            <PanelGroup className="flex-auto w-full h-full flex flex-row" autoSaveId="example" direction="vertical">
                <Panel defaultSizePercentage={70}>
                    <Editor 
                        className='border rounded-xl  overflow-hidden' 
                        defaultLanguage={skill??"javascript"} 
                        theme='vs-dark'
                        onMount={handleEditorDidMount}
                        //defaultValue="// some comment" 
                    />
                </Panel>

                <PanelResizeHandle className="rounded m-1 h-2 bg-[#26A69A] hover:opacity-75 hover:h-3 transition-all duration-200 cursor-row-resize"/>

                <Panel defaultSizePercentage={30} className="flex flex-col">
                    <Button onClick={compileCode} className="bg-primary100 rounded-full hover:bg-teal100 w-full">Compile</Button>
                    <Card className='bg-black h-full text-white p-2 overflow-y-auto overflow-x-auto'>
                    <ScrollArea className="h-[calc(100%-2rem)] w-full">
                <pre className="p-4 text-green-400 font-mono text-sm leading-relaxed whitespace-pre-wrap">
                  <span className="text-blue-400">user@machine:~$</span> cat output.txt
                  {'\n'}
                  {output.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {'\n'}
                    </React.Fragment>
                  ))}
                  <span className="text-blue-400">user@machine:~$</span> 
                  {blinkCursor && <span className="animate-pulse">▊</span>}
                </pre>
              </ScrollArea> 
                    </Card>
                </Panel>
            </PanelGroup>
        </div>
    );
};

export default CodeEditor;
//https://localhost:5173/course/67080fc46dcceaffd347b548/6700624b08f750ec96e64d4d/python/algo