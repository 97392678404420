import createRepo from "@/pages/Project/CreateProject/utils/startProject";
import { TrackContext } from "@/store/track-context";
import useWorkspace from "@/utils/Websocket/useWorkspace";
import { AxiosError } from "axios";
import { useContext, useState } from "react";
import { useGetUserDeveloper } from '../../../../openapi/api/endpoints/default/default';
import { useNavigate } from "@tanstack/react-router";
import { Skill } from "@/utils/Websocket/WorkspaceTypes";

/** 
1- start proj
2- create ws
*/

const useEvaluation = ({projID}:{projID:string}) => {
    const navigate=useNavigate()
    const [errors, setErrors]=useState<void|Error>()
    const [wsSuccess,setWSSuccess]=useState<boolean>()
    const trackCtx = useContext(TrackContext);
    const {data} = useGetUserDeveloper();
    const currentProject=trackCtx?.track?.trackProgression.find((project)=>project.projectID===projID)
    const {createWorkSpace,workspaceState,deleteWorkspace,workspaceDetails}=useWorkspace({onSuccess:()=>{
        setWSSuccess(true)
    }})

    const startEvaluation = async() => {
        if(!currentProject){
            navigate({to:'/home',from:'/'})
            throw new Error('Failed to find matching projects')
        }

        try{
            setWSSuccess(false)
            await createRepo({
                sub: data?.sub??'',
                trackID: trackCtx?.track?._id??"",
                projectID:projID??'',
                owner: data?.userName??'',
                repoName: `${projID}`,
                hiringCampaignId: trackCtx.track?.hiringCampaignId??"",
                skill:currentProject.skill??'',
                templateId:currentProject.templateId??'',
            });
            createWorkSpace({
                userName:data?.userName??'',
                projectId:projID,
                repoName:projID,
                skill:currentProject.skill as Skill
            })
            
        }catch(err){
            setWSSuccess(true)
            setErrors(new Error((err as AxiosError).message))
            throw ({startProjectErr:err})
        }
    }
    //createRepo()
    return{
        reponame:projID,
        projectID:projID,
        errors:errors,
        startEvaluation,
        workspaceState,
        deleteWorkspace,
        workspaceDetails,
        isSuccess:wsSuccess
    }
}

export default useEvaluation;