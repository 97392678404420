import { useContext } from "react"
import { TrackContext } from '../../../store/track-context';
import { useGetHiringresultsSearch } from '../../../../openapi/api/endpoints/default/default';

const useGetAverages=()=>{
    const {track}=useContext(TrackContext)
    return useGetHiringresultsSearch({sub:track?.sub, hiringCampaignId:track?.hiringCampaignId},{
        query: {
            enabled: !!track
        }
    })
    //return data
    //return useGetProjectsSearch({trackID:track?._id??''})
}

export default useGetAverages