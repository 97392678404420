import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { PostApplyBody } from "openapi/api/model";

export type CampaignDetails = {
    companyName: string;
    position: string;
    skills: string[];
    experienceLevel: string;
}

export const usePostApplication=({onSuccess, onError, onMutate, onSettled}:{onSuccess:()=>void, onError?:(error:AxiosError)=>void, onMutate?:()=>void, onSettled?:()=>void})=>{
   
    return useMutation({
        mutationFn:postApplication,
        onSuccess: ()=>{
            onSuccess&&onSuccess();
        },
        onError: (error)=>{
            onError&&onError(error as AxiosError)
        },
        onMutate: ()=>{
            onMutate&&onMutate();
        },
        onSettled: ()=>{
            onSettled&&onSettled();
        }
    })
}

const postApplication = async({formData}:{formData:PostApplyBody}) => {
    try{
        const response = await axios.post('https://api.apisdevf.net/apply/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    }catch(error){
        throw new Error(`${(error as AxiosError).message}`);
    }
}

export const useGetCampaignDetails=({id,enabled,refetchOnWindowFocus}:{id:string,enabled:boolean,refetchOnWindowFocus?:boolean})=>{ 
    return useQuery({
        queryKey: ['hiringCampaign'],
        queryFn: ()=>getCampaignDetails(id),
        enabled: enabled,
        refetchOnWindowFocus: refetchOnWindowFocus
    })
}

const getCampaignDetails = async(id:string): Promise<CampaignDetails> => {
    try{
        const response = await axios.get(`https://api.apisdevf.net/experimental/hiringcampaigns/${id}/apply`);
        return response.data;
    }catch(error){
        throw new Error(`${(error as AxiosError).message}`);
    }
}