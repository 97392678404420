export const frameworkType: Record<string,string[]> = {
    "frontend":[
        "react",
        "angular",
        "vue",
        "svelte"
    ],
    "backend":[
        "express"
    ]
}

export const AlgoBasedSkills = ['python'] as const;

export const ProjectBasedSkills = [
  'react',
  'vue',
  'angular',
  'svelte',
  'express',
] as const;

export const QAOnlySkills = [
  'devops',
  'aws',
  'docker',
  'jenkins',
  'terraform',
  'git',
  'grafana',
  'kubernetes',
  'linux',
  'prometheus',
  'bash',
  'sql'
] as const;