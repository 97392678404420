import Steps from './CreateProject/components/Backend/Steps';
import { Toaster } from "sonner"
import { MainAppRoute } from '@/rootRoutes/MainApp';
import { Route, useBlocker, useNavigate, useParams } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { WorkspaceSocket } from '@/utils/Websocket/socket';
import { stopVideoProctoring } from './VideoProctoring/VideoProctoringHooks';

export const BeginTestPage= ()=>{
  const {courseId,templateId,skill} = useParams({from:'/'});
  const projectID = courseId
  const navigate = useNavigate({from:'/'})
  const [isBlockerActive, setIsBlockerActive] = useState(true);
  const [_hasRefreshed, setHasRefreshed] = useState(false);
    
  const disableBlocker = async() => {
    setIsBlockerActive(false);
    WorkspaceSocket.disconnect()
    await stopVideoProctoring()
  }

  useEffect(() => {
    if (!isBlockerActive) {
      // Navigate to the overview page after blocker is disabled
      navigate({to:'/overview'});
    }
  }, [isBlockerActive, navigate]);

  useBlocker({
    blockerFn:async() => {
      if (!isBlockerActive) return true;
      const confirmLeave=window.confirm('Are you sure you want to leave? This would terminate the evaluation')
      if(confirmLeave){
        WorkspaceSocket.disconnect()
        await stopVideoProctoring()
      }
      return confirmLeave

    },
    condition:isBlockerActive
  })

  useEffect(() => {
    const refreshed = localStorage.getItem('hasRefreshed');
    
    if (refreshed === 'true') {
      setHasRefreshed(true); // Update state if the page was refreshed
      disableBlocker()
      console.log('Refreshed')
    } else {
      localStorage.setItem('hasRefreshed', 'true');
      console.log('Loaded')
    }

    return () => {
      localStorage.removeItem('hasRefreshed');
    };
  }, []);


  useEffect(() => {
    const preventBackNavigation = async () => {
      
      try {
        console.log('Submitting evaluation...');

        navigate({to:'/overview'}) 
      } catch (err) {
        console.error('Error during submission:', err);
      }

      
      window.history.pushState(null, '', window.location.href);
      console.log('Leaving page...');
    };

    window.addEventListener('popstate', preventBackNavigation);

    window.history.pushState(null, '', window.location.href);

    return () => {
      window.removeEventListener('popstate', preventBackNavigation);
    };
  }, [navigate]);


  return(
  <div className="flex bg-gray-100">
    <Steps theProjectID={projectID} templateId={templateId} skill={skill} blockerFn={disableBlocker}/>
    <Toaster/>
  </div>
  )
}


const BeginTestRoute = new Route({
  getParentRoute: () => MainAppRoute,
  path: "course/$courseId/$skill/project/$templateId",
  component: BeginTestPage,
})

export default BeginTestRoute;