import { useEffect, useState } from "react"

export const useTypewriter = (text: string, speed: number, onReady:() => void, scrollBottom: () => void) => {
    const [displayText, setDisplayText] = useState('')
    const [currentIndex, setCurrentIndex] = useState(0)
    const [latestMsg, setLatestMsg] =useState ('')
  
    useEffect(() => {
        if (currentIndex < text.length) {
            const timer = setTimeout(() => {
            setDisplayText(prev => prev + text[currentIndex])
            setCurrentIndex(prev => prev + 1)
            scrollBottom();
            }, speed)
            //console.log('faxx or nahhh: ',displayText===text)
            return () => clearTimeout(timer);
        } 
        //console.log('lst msg:  ',latestMsg)
        //console.log('lst msng true: ',(latestMsg !=text))
        if (displayText === text && latestMsg !=text) {
            // Call onReady once typing is complete and displayText matches text
            //also prevents to call the onReady before receiving the new question from bot
            onReady();
            setLatestMsg(text);
        }
        //console.log('faxx or nahhh2x: ',displayText===text)
    }, [text, speed, currentIndex, displayText, onReady]);

    return displayText
}
